const TaxOwners = {
    Company: 1,
    Person: 2
};

const GetTaxOwnerName = function(taxOwner) {
    if(taxOwner == TaxOwners.Company) {
        return "Empresa";
    }
    else if(taxOwner == TaxOwners.Person) {
        return "Pessoa";
    }
    return "";
}

export {TaxOwners, GetTaxOwnerName};