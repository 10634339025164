<!-- <template>
  <div>
    <div class="cart-father">
      <!-- BOX DE PROJETOS -->
      <v-layout content-cart>
        <v-layout box-project-cart column align-start>
          <v-flex
            bg-white
            indique-valor
            shrink
            v-if="campaignCheckoutData.campaignTypeId != CampaignTypes.Ticket"
          >
            <p class="px-4 pt-4 pb-2 ma-0 f-size-16 color-theme-texto">
              Indique nos campos de cada projeto o valor que deseja doar. O
              valor mínimo desta campanha é
              <span>{{
                campaignCheckoutData.minimumDonationValue | FormatMoney
              }}</span>
            </p>
            <p class="px-4">
              <v-switch
                v-if="campaignCheckoutData.allowUsdDonation"
                v-model="enabledUSDDonation"
                :label="'Doar em dolar'"
              ></v-switch
              >{{ usdDonation }}
            </p>
          </v-flex>
          <v-flex bg-white indique-valor shrink v-else>
            <p class="px-4">
              <v-switch
                v-if="campaignCheckoutData.allowUsdDonation"
                v-model="enabledUSDDonation"
                :label="'Doar em dolar'"
              ></v-switch>
            </p>
          </v-flex>
          <v-flex scroll-box-project v-if="cartItems.length > 0">
            <div v-for="cartItem in cartItems" :key="cartItem.id">
              <v-flex replace-box-project pa-4>
                <v-layout row break-name p-relative>
                  <v-flex img-project-cart shrink mr-4>
                    <FileImage
                      :file="cartItem.project.projectLandingPageConfig.heroFile"
                      :alt="''"
                    />
                  </v-flex>
                  <v-flex shrink content-box w-100>
                    <h5 class="pr-4 mt-3 color-theme-destaque">
                      {{ cartItem.project.institution.entity.name }}
                    </h5>
                    <h3 class="fw-400 color-theme-texto">
                      {{ cartItem.project.shortName }}
                    </h3>
                    <InputMoney
                      v-if="
                        cartItem.project.projectTypeId != ProjectTypes.Ticket
                      "
                      v-model="cartItem.itemValue"
                      :precision="0"
                      suffix=",⁰⁰"
                      :prefix="usdDonationInfo.prefix"
                      ref="toInput"
                      :pattern="'\\d*'"
                      invalidInputMessage="Preencha o valor da doação"
                    />
                    <div class="number-combo" v-else>
                      <button
                        v-if="!cartItem.donationProduct.openValue"
                        v-on:disabled="
                          minimumPurchase(cartItem.donationProduct)
                        "
                        @click="cartItem.donationProduct.currentQuantity--"
                      >
                        <i class="fal fa-minus"></i>
                      </button>
                      <div
                        class="t-center"
                        v-if="!cartItem.donationProduct.openValue"
                      >
                        <label class="label-qtde-cart">Qtde</label>
                        <input
                          style="width: auto"
                          id="sizeWindow"
                          type="number"
                          min="0"
                          max="99999"
                          v-model="cartItem.donationProduct.currentQuantity"
                          @change="changeCurrentQuantity(cartItem)"
                        />
                      </div>
                      <button
                        v-if="!cartItem.donationProduct.openValue"
                        :disabled="maximumPurchase(cartItem)"
                        @click="cartItem.donationProduct.currentQuantity++"
                      >
                        <i class="fal fa-plus"></i>
                      </button>
                      <div
                        v-if="!cartItem.donationProduct.openValue"
                        class="value-cart-field"
                        v-bind:class="{
                          'value-cart-field-br': !selectDollar,
                          'value-cart-field-us': selectDollar,
                        }"
                        style="width: auto; padding-left: 1rem"
                      >
                        <input
                          style="
                            border: 0 none;
                            height: 44px;
                            outline: none;
                            width: auto;
                          "
                          id="sizeWindow"
                          type="number"
                          min="0"
                          max="9999999"
                          :value="
                            cartItem.donationProduct.currentQuantity *
                              cartItem.donationProduct.fixedValue
                          "
                          @blur="changeCurrentValue(cartItem, $event)"
                        />
                      </div>
                      <div
                        v-else
                        class="value-cart-field"
                        v-bind:class="{
                          'value-cart-field-br': !selectDollar,
                          'value-cart-field-us': selectDollar,
                        }"
                        style="width: auto; padding-left: 1rem"
                      >
                        <input
                          style="
                            border: 0 none;
                            height: 44px;
                            outline: none;
                            width: auto;
                          "
                          id="sizeWindow"
                          type="number"
                          min="0"
                          max="9999999"
                          v-model="cartItem.donationProduct.fixedValue"
                          @blur="changeCurrentValue(cartItem, $event)"
                        />
                      </div>
                    </div>
                    <p
                      v-if="
                        cartItem.donationProduct &&
                          cartItem.donationProduct.suggestionValue
                      "
                    >
                      Valor sugerido:
                      {{ cartItem.donationProduct.suggestionValue }}
                    </p>
                  </v-flex>
                  <a
                    href="#"
                    class="close-project-cart"
                    @click="
                      openRemoveCartItem(cartItem);
                      $gtag(
                        'send',
                        'event',
                        'Remover',
                        'Carrinho Doação',
                        'Icone Lixeira'
                      );
                    "
                  >
                    <Icon name="fal fa-trash" size="20" colorFont="#cfd2dd" />
                  </a>
                </v-layout>
                <v-flex mt-4 xs12 v-if="cartItem.donationProduct">
                  <v-layout sub-project>
                    <v-flex shrink mr-3>
                      <FileImage
                        :file="cartItem.donationProduct.file"
                        :alt="''"
                      />
                    </v-flex>
                    <v-flex shrink content-box>
                      <span
                        class="color-theme-destaque"
                        v-if="
                          cartItem.project.projectTypeId != ProjectTypes.Ticket
                        "
                        >Você escolheu doar o valor acima para:</span
                      >
                      <span class="color-theme-destaque" v-else>{{
                        cartItem.donationProduct.finalDate
                          ? formatterHelper.formatDate(
                              cartItem.donationProduct.initialDate
                            ) +
                            " - " +
                            formatterHelper.formatDate(
                              cartItem.donationProduct.finalDate
                            )
                          : formatterHelper.formatDate(
                              cartItem.donationProduct.initialDate
                            )
                      }}</span>
                      <h3 class="my-1 fw-400 color-theme-texto">
                        {{ cartItem.donationProduct.name }}
                      </h3>
                      <p
                        class="color-theme-padrao"
                        v-html="cartItem.donationProduct.description"
                      ></p>
                    </v-flex>
                  </v-layout>
                  <template v-if="cartItem.donationProduct.showQuestions">
                    <template
                      v-for="(donationProductQuestion,
                      index) in cartItem.donationProduct.donationProductQuestionList.filter(
                        (x) => x.active == true
                      )"
                    >
                      <InputText
                        v-if="!donationProductQuestion.hasAlternatives"
                        ref="donationProductAnswerInput"
                        :key="`${index}-answer-text`"
                        type="text"
                        :textLabel="donationProductQuestion.question"
                        v-model="donationProductQuestion.answer"
                        invalidInputMessage="Entre com uma resposta válida"
                      />

                      <InputSelect
                        v-else
                        ref="donationProductSelectAnswerInput"
                        :items="
                          donationProductQuestion.donationProductQuestionAlternatives
                        "
                        valueAttribute="description"
                        textAttribute="description"
                        :key="`${index}-answer-alternative`"
                        type="text"
                        :textLabel="donationProductQuestion.question"
                        v-model="donationProductQuestion.answer"
                        invalidInputMessage="Escolha uma opção"
                      />
                    </template>
                  </template>
                </v-flex>
              </v-flex>
            </div>
          </v-flex>
          <div class="add-project bg-white">
            <button
              @click="
                addMoreProjects();
                $gtag(
                  'send',
                  'event',
                  'Add Mais Projetos',
                  'Carrinho Doação',
                  'Adicionar mais projetos'
                );
              "
            >
              <i class="fal fa-plus"></i>
              {{ "Adicionar mais " + itemsOrProjects }}
            </button>
          </div>
        </v-layout>
        <v-flex box-payment-cart>
          <div class="bg-white pa-4 column-center">
            <div class="project-for-cart display-flex mb-2">
              <Icon name="fal fa-shopping-cart" size="47" colorFont="#fa6e6e" />
              <div class="pl-3">
                <h2 class="fw-400 ma-0 color-theme-texto">
                  <span>Doar para</span>
                  {{
                    cartItems.length == 1
                      ? cartItems.length + " " + itemOrProject
                      : cartItems.length + " " + itemsOrProjects
                  }}
                </h2>
                <p class="color-theme-texto">
                  Nós lhe enviaremos atualizações das organizações sobre o seu
                  impacto!
                </p>
              </div>
              <!-- Por enquanto não haverá doação em nome de terceiro -->
              <button
                  v-show="false"
                  @click="openSimulationAlertCart(); $gtag('send', 'event', 'Carrinho', 'clicou', 'Doar em nome de algueum')"
                  class="v-btn--large btn-cart-present"
              >
                  <Icon name="fal fa-gift" size="16" colorFont="#FFB400" class="pr-2" />Quero doar em nome de alguém
              </button>
              <button
                v-show="hasProjectIncentived"
                @click="toggleSimulation(); $gtag('send', 'event', 'Simulador carrinho', 'Simulacao carrinho', 'Simulador carrinho')"
                class="btn-cart-simulation mr-3"
              >
                <Icon
                  name="fal fa-calculator-alt"
                  size="16"
                  colorFont="#fff"
                  class="pr-2"
                />
                  {{ this.getSimulationResult() > 0 ? "Nova simulação" : "Faça uma simulação" }}
              </button>
              <AucAlert
                ref="confirmationComponent"
                icon="fal fa-calculator-alt"
                :title="`Já sabe qual o seu potencial de doação para um projeto incentivado? Você pode direcionar até ${incomeTax} do imposto devido* para o projeto selecionado.`"
                confirmButtonText="Faça uma simulação"
                cancelButtonText="Não, obrigado"
                @confirm="toggleSimulation"
                :withStorage="true"
                :startOpen="this.hasProjectIncentived && this.getSimulationResult() == 0"
              />
              {{
                this.getSimulationResult() > 0
                  ? "Nova simulação"
                  : "Faça uma simulação"
              }}
            </button>
            <AucAlert
              ref="confirmationComponent"
              icon="fal fa-calculator-alt"
              :title="
                `Já sabe qual o seu potencial de doação para um projeto incentivado? Você pode direcionar até ${simulatorPercentage} do imposto devido* para o projeto selecionado.`
              "
              confirmButtonText="Faça uma simulação"
              cancelButtonText="Não, obrigado"
              @confirm="toggleSimulation"
              :withStorage="true"
              :startOpen="
                this.hasProjectIncentived && this.getSimulationResult() == 0
              "
            />
          </div>
          <div>
            <v-tabs
              v-model="cartLocal.paymentMethodId"
              class="tab-payment tabs-cart"
              centered
              color="white"
              dark
              icons-and-text
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-show="false" class="pa-0 ma-0" style="min-width: 0px" />

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-tab
                    class="tabs"
                    :href="'#' + PaymentMethod.PayrollDeduction"
                    v-if="
                      containsPaymentMethodAndFitsTheChosenRecurrencePeriod(
                        PaymentMethod.PayrollDeduction
                      )
                    "
                    v-on="on"
                    @click="
                      onClickTab(PaymentMethod.PayrollDeduction);
                      $gtag(
                        'send',
                        'event',
                        'Carrinho',
                        'clicou',
                        'Débito em Folha'
                      );
                      $gtag('send', 'pageview', '/carrinho/debito-folha');
                    "
                  >
                    <Icon
                      name="fas fa-money-check-alt"
                      size="16"
                      colorFont="#C3C6D5"
                      class="pr-2"
                    />
                    <span>Folha de pagamento</span>
                  </v-tab>
                </template>
                <span>Folha de pagamento</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-tab
                    class="tabs"
                    :href="'#' + PaymentMethod.CreditCard"
                    :disabled="cartItems.length < 1"
                    v-if="
                      containsPaymentMethodAndFitsTheChosenRecurrencePeriod(
                        PaymentMethod.CreditCard
                      )
                    "
                    v-on="on"
                    @click="
                      onClickTab(PaymentMethod.CreditCard);
                      $gtag('send', 'pageview', '/carrinho/cartao-credito');
                    "
                  >
                    <Icon
                      name="fas fa-credit-card"
                      size="16"
                      colorFont="#C3C6D5"
                      class="pr-2"
                    />
                    <span>Cartão de Crédito</span>
                  </v-tab>
                </template>
                <span>Cartão de Crédito</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-tab
                    class="tabs"
                    :href="'#' + PaymentMethod.Ticket"
                    :disabled="cartItems.length < 1"
                    v-if="
                      containsPaymentMethodAndFitsTheChosenRecurrencePeriod(
                        PaymentMethod.Ticket
                      )
                    "
                    v-on="on"
                    @click="
                      onClickTab(PaymentMethod.Ticket);
                      $gtag('send', 'pageview', '/carrinho/boleto');
                    "
                  >
                    <Icon
                      name="fas fa-barcode-alt"
                      size="16"
                      colorFont="#C3C6D5"
                      class="pr-2"
                    />
                    <span>Boleto</span>
                  </v-tab>
                </template>
                <span>Boleto</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-tab
                    class="tabs"
                    :href="'#' + PaymentMethod.AccountDebit"
                    :disabled="cartItems.length < 1"
                    v-if="
                      containsPaymentMethodAndFitsTheChosenRecurrencePeriod(
                        PaymentMethod.AccountDebit
                      )
                    "
                    v-on="on"
                    @click="
                      onClickTab(PaymentMethod.AccountDebit);
                      $gtag(
                        'send',
                        'event',
                        'Carrinho',
                        'clicou',
                        'Débito em Conta'
                      );
                    "
                  >
                    <Icon
                      name="fas fa-file-invoice-dollar"
                      size="16"
                      colorFont="#C3C6D5"
                      class="pr-2"
                    />
                    <span>Débito em conta</span>
                  </v-tab>
                </template>
                <span>Débito em conta</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-tab
                    class="tabs"
                    :href="'#' + PaymentMethod.Pix"
                    :disabled="cartItems.length < 1"
                    v-if="
                      containsPaymentMethodAndFitsTheChosenRecurrencePeriod(
                        PaymentMethod.Pix
                      )
                    "
                    v-on="on"
                    @click="onClickTab(PaymentMethod.Pix)"
                  >
                    <i class="pr-2" style="color: #c3c6d5">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.83025 5.30642C5.64648 5.30642 6.41419 5.61958 6.99139 6.18805L10.1233 9.27459C10.3488 9.49694 10.7171 9.49777 10.9432 9.27433L14.0637 6.1993C14.6409 5.63083 15.4086 5.31767 16.2248 5.31767H16.6007L12.6373 1.41231C11.4029 0.196059 9.40173 0.196059 8.16749 1.41231L4.21548 5.30643L4.83025 5.30642ZM16.2249 16.1824C15.4086 16.1824 14.6409 15.8693 14.0637 15.3008L10.9432 12.226C10.7241 12.0095 10.3423 12.0102 10.1233 12.226L6.99143 15.312C6.41422 15.8805 5.64651 16.1937 4.83029 16.1937H4.21533L8.16753 20.0879C9.40176 21.3041 11.403 21.3041 12.6373 20.0879L16.6007 16.1824H16.2249ZM17.4839 6.1879L19.879 8.54791C21.1133 9.76415 21.1133 11.7359 19.879 12.9522L17.4839 15.3122C17.4312 15.2914 17.3741 15.2784 17.3138 15.2784H16.2248C15.6618 15.2784 15.1107 15.0534 14.7129 14.6611L11.5925 11.5866C11.027 11.0289 10.0402 11.029 9.47408 11.5864L6.34222 14.6724C5.94439 15.0646 5.39326 15.2896 4.83027 15.2896H3.49125C3.43413 15.2896 3.38076 15.3028 3.33028 15.3215L0.925749 12.9522C-0.308583 11.736 -0.308583 9.76416 0.925749 8.54791L3.33038 6.1785C3.38076 6.1972 3.43413 6.21047 3.49125 6.21047H4.83027C5.39326 6.21047 5.94439 6.43539 6.34222 6.82775L9.47438 9.91402C9.7663 10.2015 10.1497 10.3454 10.5334 10.3454C10.9168 10.3454 11.3005 10.2015 11.5925 9.91374L14.7129 6.83899C15.1107 6.44671 15.6618 6.2217 16.2248 6.2217H17.3138C17.3741 6.2217 17.4312 6.20871 17.4839 6.1879Z"
                          fill="currentColor"
                        />
                      </svg>
                    </i>
                    <span>Pix</span>
                  </v-tab>
                </template>
                <span>Pague com Pix</span>
              </v-tooltip>

              <v-tab-item
                style="background-color: transparent !important; border: none; box-shadow: none;"
              >
                <div class="alert-login-cart">
                  <i class="fas fa-share fa-rotate-270"></i>
                  Selecione acima o meio
                  <br />de pagamento desejado
                </div>
              </v-tab-item>

              <v-tab-item
                class="px-4 bg-white"
                :value="PaymentMethod.PayrollDeduction.toString()"
                v-if="logged"
              >
                <div class="box-total">
                  <div class="mw-total price">
                    <span class="color-theme-texto">{{
                      campaignLandingPageConfig.campaign.campaignTypeId ==
                      CampaignTypes.Ticket
                        ? "Total a ser doado:"
                        : "Total a ser doado:"
                    }}</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0 notranslate">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                      <span class="color-theme-primaria">{{
                        recurrenceText
                      }}</span>
                    </div>
                  </div>
                  <!-- aparece somente se usuario inserir valor menor -->
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="
                      cartTotalPrice > 0 &&
                        cartTotalPrice <
                          campaignLandingPageConfig.campaign
                            .minimumDonationValue
                    "
                  >
                    <span
                      >Mínimo para doação R${{
                        formatterHelper.formatMoney(
                          campaignLandingPageConfig.campaign
                            .minimumDonationValue,
                          0
                        )
                      }}</span
                    >
                  </div>
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="selectDollar"
                  >
                    <span
                      >* You will be charged in Brazillian Reais. It will show
                      up as an international payment on your credit card
                      bill.</span
                    >
                  </div>
                  <div class="mw-total">
                    <div :id="parcelamentsInputId">
                      <InputSelect
                        ref="recurrencePeriodInput"
                        v-model="cart.donationRecurrencePeriodId"
                        :textLabel="
                          'Como deseja fazer esta ' + donationOrPurchase + ':'
                        "
                        helpLabel="Escolha se sua doação será única ou recorrente"
                        :items="campaignDonationRecurrencePeriodList"
                        valueAttribute="id"
                        textAttribute="name"
                        invalidInputMessage="Escolha se sua doação será única ou recorrente"
                        @input="checkPayRollInput"
                      ></InputSelect>
                      <InputSelect
                        v-model="cart.parcelamentId"
                        textLabel="Selecione a quantidade de parcelas:"
                        helpLabel="Escolha o parcelamento da sua doação"
                        :items="parcelamentsDivided"
                        valueAttribute="id"
                        textAttribute="description"
                        invalidInputMessage="Escolha o parcelamento da sua doação"
                        v-if="showPayRollInput && cartTotalPrice > 0"
                      ></InputSelect>
                    </div>
                    <div class="box-info-data-payment c-text-dark">
                      <div
                        v-if="hasCompany"
                        class="display-flex justify-space-between"
                      >
                        <div class="text-color">Empresa</div>
                        <div class="text-color">CNPJ</div>
                      </div>
                      <div
                        v-if="hasCompany"
                        class="display-flex justify-space-between data"
                      >
                        <div class="c-text-dark">{{ titleCompany }}</div>
                        <div class="c-text-dark">{{ cnpjCompany | cnpj }}</div>
                      </div>
                      <p v-if="hasCompany">{{ textCompany }}</p>
                      <hr class="sm my-3" />
                      <div
                        class="check-terms"
                        @click="
                          openConsentTerm(PaymentMethod.PayrollDeduction);
                          $gtag(
                            'send',
                            'event',
                            'LiTermoAnuência FolhaPagamento',
                            'Carrinho Doação',
                            'Termo de Anuência'
                          );
                        "
                      >
                        <v-checkbox
                          :value="terms"
                          :disabled="true"
                          label="Declaro que li e concordo com o Termo de Anuência"
                        ></v-checkbox>
                      </div>
                      <hr class="sm my-3" />
                      <button
                        class="btn-boleto fw-600"
                        @click="
                          checkout();
                          $gtag(
                            'send',
                            'event',
                            'Carrinho',
                            'clicou',
                            'Concluir doação débito em folha'
                          );
                        "
                        :disabled="cartItems.length < 1"
                      >
                        <Icon
                          name="fas fa-money-check-alt"
                          size="16"
                          colorFont="#fff"
                          class="mr-2 text-white fw-600"
                        />
                        Concluir {{ donationOrPurchase }} com débito em folha
                      </button>
                    </div>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item
                class="px-4 bg-white"
                :value="PaymentMethod.CreditCard.toString()"
                v-if="logged"
              >
                <div class="box-total">
                  <div class="mw-total price">
                    <span class="color-theme-texto">{{
                      campaignLandingPageConfig.campaign.campaignTypeId ==
                      CampaignTypes.Ticket
                        ? "Total a ser doado:"
                        : "Total a ser doado:"
                    }}</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0 notranslate">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                      <span class="color-theme-primaria">{{
                        recurrenceText
                      }}</span>
                    </div>
                  </div>
                  <!-- aparece somente se usuario inserir valor menor -->
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="
                      cartTotalPrice > 0 &&
                        cartTotalPrice < minimumPeriodDonationValue
                    "
                  >
                    <span
                      >Mínimo para doação R${{
                        formatterHelper.formatMoney(
                          minimumPeriodDonationValue,
                          0
                        )
                      }}</span
                    >
                  </div>
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="selectDollar"
                  >
                    <span
                      >* You will be charged in Brazillian Reais. It will show
                      up as an international payment on your credit card
                      bill.</span
                    >
                  </div>
                  <div class="mw-total">
                    <InputSelect
                      ref="recurrencePeriodInput"
                      v-model="cart.donationRecurrencePeriodId"
                      :textLabel="
                        'Como deseja fazer esta ' + donationOrPurchase + ':'
                      "
                      helpLabel="Escolha se sua doação será única ou recorrente"
                      :items="campaignDonationRecurrencePeriodList"
                      valueAttribute="id"
                      textAttribute="name"
                      invalidInputMessage="Escolha se sua doação será única ou recorrente"
                      v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                      @input="keyCreditCardParcelamentChange($event)"
                    ></InputSelect>
                  </div>
                  <div
                    :key="`recurrence-${keyCreditCardParcelament}`"
                    class="mw-total"
                    v-if="
                      this.cartTotalPrice > 0 &&
                        verifyParcelamentPaymentMethod()
                    "
                  >
                    <span class="color-theme-texto"
                      >Selecione a quantidade de parcelas:</span
                    >
                    <span
                      class="color-theme-texto"
                      style="float: right; font-weight: bold"
                      >Valor mínimo da parcela:
                      {{
                        campaignLandingPageConfig.campaign
                          .minimumParcelamentValue | FormatMoney
                      }}</span
                    >
                    <div :key="`parcelament-${keyCreditCardParcelament}`">
                      <InputSelect
                        :items="GethowMany"
                        v-model="cart.creditCardParcelament"
                        :disabled="false"
                        valueAttribute="quantidade"
                        textAttribute="name"
                        style="width: 50%"
                        :key="GethowManyTicketParcelament"
                        @input="
                          showParcelamentMessage(cart.creditCardParcelament)
                        "
                      ></InputSelect>
                    </div>
                  </div>
                  <div
                    v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                    class="mw-total"
                  >
                    <div class="ml-2">
                      <span class="color-theme-texto"
                        >Taxa do cartão de crédito:</span
                      >
                      <span
                        class="color-theme-texto"
                        style="float: right; font-weight: bold"
                        >R${{
                          formatterHelper.formatMoney(cartTaxMethodPayment(), 2)
                        }}</span
                      >
                    </div>

                    <div class="ml-2">
                      <span class="color-theme-texto">Valor total:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R${{ getParcelamentTotalValue }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="box-info-data-payment mw-total"
                  v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                >
                  <h4 class="title-boleto">Dados do Titular</h4>
                  <div class="form-grid" @click.once="gaLightBoxDadosTitular()">
                    <v-switch
                      v-model="addNewCard"
                      inset
                      :label="'Adicionar Novo Cartão de Crédito'"
                    ></v-switch>
                    <div v-if="!addNewCard">
                      <h2 class="fw-400 color-theme-texto">
                        Selecione o cartão
                      </h2>
                      <table class="v-datatable v-table theme--light">
                        <thead>
                          <tr>
                            <th class="text-sm-center" width="40">#</th>
                            <th class="text-sm-left">Número</th>
                            <th class="text-sm-left">Nome</th>
                            <th class="text-sm-left">Bandeira</th>
                            <th class="text-sm-center" width="40">#</th>
                          </tr>
                        </thead>
                        <tbody class="notranslate">
                          <tr
                            v-for="item in creditCards"
                            :key="item.id"
                            :style="
                              item.id === selectedCardId
                                ? 'background: rgba(104, 187, 108, 0.1)'
                                : ''
                            "
                            @click="selectCreditCard(item.id, item)"
                          >
                            <td>
                              <a>
                                <v-icon
                                  v-if="item.id === selectedCardId"
                                  color="green lighten-1"
                                  >radio_button_checked</v-icon
                                >
                                <v-icon v-else color="gray lighten-1"
                                  >radio_button_unchecked</v-icon
                                >
                              </a>
                            </td>
                            <td>**** **** **** {{ item.lastDigits }}</td>
                            <td>{{ item.cardName }}</td>
                            <td v-if="item.firstDigits[0] == '4'">Visa</td>
                            <td v-if="item.firstDigits[0] == '5'">
                              Master Card
                            </td>
                            <td>
                              <v-btn
                                small
                                flat
                                fab
                                @click="deleteCreditCard(item.id)"
                              >
                                <Icon
                                  name="fal fa-trash"
                                  size="20"
                                  colorFont="#cfd2dd"
                                />
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="addNewCard">
                      <h2 class="fw-400 color-theme-texto">
                        Adicionar Novo Cartão.
                      </h2>
                      <v-layout row wrap style="margin: auto -16px">
                        <v-flex xs12 md6 pl-3>
                          <InputText
                            ref="cardNumberInput"
                            type="tel"
                            textLabel="Número do cartão"
                            autocomplete="cc-number"
                            place-holder="0000 0000 0000 0000"
                            v-model="
                              cart.checkoutRequest.creditCardInfo.cardNumber
                            "
                            mask="#### #### #### ####"
                            invalidInputMessage="Preencha o número do cartão"
                            :validation="creditCardNumberValidation"
                          />
                        </v-flex>
                        <v-flex xs12 md6 px-3>
                          <InputText
                            ref="holderNameInput"
                            type="text"
                            textLabel="Nome"
                            autocomplete="cc-name"
                            place-holder="Como está escrito no cartão"
                            v-model="
                              cart.checkoutRequest.creditCardInfo.holder.name
                            "
                            :labelCharLimit="100"
                            invalidInputMessage="Preencha o nome que consta no cartão"
                            @input="nameToUpperCase()"
                          />
                        </v-flex>
                        <v-flex xs12 md6 pl-3>
                          <InputText
                            ref="expirationDateInput"
                            type="tel"
                            autocomplete="cc-exp"
                            textLabel="Data de expiração"
                            place-holder="MM/AAAA"
                            :value="getExpirationMonthAndYear()"
                            @input="updateExpirationMonthAndYear($event)"
                            mask="##/####"
                            invalidInputMessage="Preencha a data de expiração"
                            :validation="expirationDateValidation"
                          />
                        </v-flex>
                        <v-flex xs12 md6 px-3>
                          <InputText
                            ref="cvvInput"
                            type="tel"
                            textLabel="Código de Segurança"
                            place-holder="CVV"
                            autocomplete="cc-csc"
                            v-model="cart.checkoutRequest.creditCardInfo.cvv"
                            mask="####"
                            invalidInputMessage="Preencha o código de segurança"
                            :validation="cvvValidation"
                          />
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-switch
                          v-model="saveCreditCard"
                          inset
                          :label="'Salvar novo cartão?'"
                        ></v-switch>
                      </v-layout>
                    </div>
                    <template v-if="!isPaymentSimplified">
                      <v-flex xs12 md6>
                        <InputText
                          ref="holderCPFInput"
                          type="tel"
                          :textLabel="
                            campaignLandingPageConfig.campaign
                              .permitCnpjDonation
                              ? 'CPF/CNPJ'
                              : 'CPF'
                          "
                          :place-holder="
                            `Digite o ${
                              campaignLandingPageConfig.campaign
                                .permitCnpjDonation
                                ? 'CPF/CNPJ'
                                : 'CPF'
                            }`
                          "
                          v-model="
                            cartLocal.checkoutRequest.creditCardInfo.holder.CPF
                          "
                          :mask="
                            campaignLandingPageConfig.campaign
                              .permitCnpjDonation
                              ? ['###.###.###-##', '##.###.###/####-##']
                              : ['###.###.###-##']
                          "
                          :invalidInputMessage="
                            `Preencha o ${
                              campaignLandingPageConfig.campaign
                                .permitCnpjDonation
                                ? 'CPF/CNPJ'
                                : 'CPF'
                            } do títular`
                          "
                        />
                      </v-flex>
                    </template>
                  </div>
                  <hr class="sm my-2" />
                  <v-layout row wrap>
                    <div
                      class="check-terms"
                      @click="
                        openConsentTerm(PaymentMethod.CreditCard);
                        $gtag(
                          'send',
                          'event',
                          'LiTermoAnuência CartãoCrédito',
                          'Carrinho Doação',
                          'Termo de Anuência'
                        );
                      "
                    >
                      <v-checkbox
                        :value="terms"
                        :disabled="true"
                        label="Declaro que li e concordo com o Termo de Anuência"
                      ></v-checkbox>
                    </div>
                  </v-layout>
                  <hr class="sm my-3" />
                  <button
                    class="btn-boleto mt-2 fw-700"
                    @click="
                      creditCardCheckout();
                      $gtag(
                        'send',
                        'event',
                        'Forma de pagamento Cartao de credito',
                        'Forma de pagamento cartao de credito',
                        'Cartao de credito'
                      );
                    "
                    :disabled="cartItems.length < 1"
                  >
                    <Icon
                      name="fas fa-credit-card"
                      size="16"
                      colorFont="#fff"
                      class="pr-2 text-white"
                    />
                    Concluir {{ donationOrPurchase }} cartão de crédito
                  </button>
                </div>
              </v-tab-item>

              <v-tab-item
                class="px-4 bg-white"
                :value="PaymentMethod.CreditCard.toString()"
                v-if="logged"
              >
                <div class="box-total">
                  <div class="mw-total price">
                    <span class="color-theme-texto">{{
                      campaignLandingPageConfig.campaign.campaignTypeId ==
                      CampaignTypes.Ticket
                        ? "Total a ser doado:"
                        : "Total a ser doado:"
                    }}</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0 notranslate">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                      <span class="color-theme-primaria">{{
                        recurrenceText
                      }}</span>
                    </div>
                  </div>
                  <!-- aparece somente se usuario inserir valor menor -->
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="
                      cartTotalPrice > 0 &&
                        cartTotalPrice < minimumPeriodDonationValue
                    "
                  >
                    <span
                      >Mínimo para doação R${{
                        formatterHelper.formatMoney(
                          minimumPeriodDonationValue,
                          0
                        )
                      }}</span
                    >
                  </div>
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="selectDollar"
                  >
                    <span
                      >* You will be charged in Brazillian Reais. It will show
                      up as an international payment on your credit card
                      bill.</span
                    >
                  </div>
                  <div class="mw-total">
                    <InputSelect
                      ref="recurrencePeriodInput"
                      v-model="cart.donationRecurrencePeriodId"
                      :textLabel="
                        'Como deseja fazer esta ' + donationOrPurchase + ':'
                      "
                      helpLabel="Escolha se sua doação será única ou recorrente"
                      :items="campaignDonationRecurrencePeriodList"
                      valueAttribute="id"
                      textAttribute="name"
                      invalidInputMessage="Escolha se sua doação será única ou recorrente"
                      v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                      @input="keyCreditCardParcelamentChange($event)"
                    ></InputSelect>
                  </div>
                  <div
                    :key="`recurrence-${keyCreditCardParcelament}`"
                    class="mw-total"
                    v-if="
                      this.cartTotalPrice > 0 &&
                        verifyParcelamentPaymentMethod()
                    "
                  >
                    <span class="color-theme-texto"
                      >Selecione a quantidade de parcelas:</span
                    >
                    <span
                      class="color-theme-texto"
                      style="float: right; font-weight: bold"
                      >Valor mínimo da parcela:
                      {{
                        campaignLandingPageConfig.campaign
                          .minimumParcelamentValue | FormatMoney
                      }}</span
                    >
                    <div :key="`parcelament-${keyCreditCardParcelament}`">
                      <InputSelect
                        :items="GethowMany"
                        v-model="cart.creditCardParcelament"
                        :disabled="false"
                        valueAttribute="quantidade"
                        textAttribute="name"
                        style="width: 50%"
                        :key="GethowManyTicketParcelament"
                        @input="
                          showParcelamentMessage(cart.creditCardParcelament)
                        "
                      ></InputSelect>
                    </div>
                  </div>
                  <div
                    v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                    class="mw-total"
                  >
                    <div class="ml-2">
                      <span class="color-theme-texto"
                        >Taxa do cartão de crédito:</span
                      >
                      <span
                        class="color-theme-texto"
                        style="float: right; font-weight: bold"
                        >R${{
                          formatterHelper.formatMoney(cartTaxMethodPayment(), 2)
                        }}</span
                      >
                    </div>

                    <div class="ml-2">
                      <span class="color-theme-texto">Valor total:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R${{ getParcelamentTotalValue }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="box-info-data-payment mw-total"
                  v-if="cart.paymentMethodId == paymentMethods.CreditCard"
                >
                  <h4 class="title-boleto">Dados do Titular</h4>
                  <div class="form-grid" @click.once="gaLightBoxDadosTitular()">
                    <v-switch
                      v-model="addNewCard"
                      inset
                      :label="'Adicionar Novo Cartão de Crédito'"
                    ></v-switch>
                    <div v-if="!addNewCard">
                      <h2 class="fw-400 color-theme-texto">
                        Selecione o cartão
                      </h2>
                      <table class="v-datatable v-table theme--light">
                        <thead>
                          <tr>
                            <th class="text-sm-center" width="40">#</th>
                            <th class="text-sm-left">Número</th>
                            <th class="text-sm-left">Nome</th>
                            <th class="text-sm-left">Bandeira</th>
                            <th class="text-sm-center" width="40">#</th>
                          </tr>
                        </thead>
                        <tbody class="notranslate">
                          <tr
                            v-for="item in creditCards"
                            :key="item.id"
                            :style="
                              item.id === selectedCardId
                                ? 'background: rgba(104, 187, 108, 0.1)'
                                : ''
                            "
                            @click="selectCreditCard(item.id, item)"
                          >
                            <td>
                              <a>
                                <v-icon
                                  v-if="item.id === selectedCardId"
                                  color="green lighten-1"
                                  >radio_button_checked</v-icon
                                >
                                <v-icon v-else color="gray lighten-1"
                                  >radio_button_unchecked</v-icon
                                >
                              </a>
                            </td>
                            <td>**** **** **** {{ item.lastDigits }}</td>
                            <td>{{ item.cardName }}</td>
                            <td v-if="item.firstDigits[0] == '4'">Visa</td>
                            <td v-if="item.firstDigits[0] == '5'">
                              Master Card
                            </td>
                            <td>
                              <v-btn
                                small
                                flat
                                fab
                                @click="deleteCreditCard(item.id)"
                              >
                                <Icon
                                  name="fal fa-trash"
                                  size="20"
                                  colorFont="#cfd2dd"
                                />
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="addNewCard">
                      <h2 class="fw-400 color-theme-texto">
                        Adicionar Novo Cartão.
                      </h2>
                      <v-layout row wrap style="margin: auto -16px">
                        <v-flex xs12 md6 pl-3>
                          <InputText
                            ref="cardNumberInput"
                            type="tel"
                            textLabel="Número do cartão"
                            autocomplete="cc-number"
                            place-holder="0000 0000 0000 0000"
                            v-model="
                              cartLocal.checkoutRequest.creditCardInfo
                                .cardNumber
                            "
                            mask="#### #### #### ####"
                            invalidInputMessage="Preencha o número do cartão"
                            :validation="creditCardNumberValidation"
                          />
                        </v-flex>
                        <v-flex xs12 md6 px-3>
                          <InputText
                            ref="holderNameInput"
                            type="text"
                            textLabel="Nome"
                            autocomplete="cc-name"
                            place-holder="Como está escrito no cartão"
                            v-model="
                              cartLocal.checkoutRequest.creditCardInfo.holder
                                .name
                            "
                            :labelCharLimit="100"
                            invalidInputMessage="Preencha o nome que consta no cartão"
                            @input="nameToUpperCase()"
                          />
                        </v-flex>
                        <v-flex xs12 md6 pl-3>
                          <InputText
                            ref="expirationDateInput"
                            type="tel"
                            autocomplete="cc-exp"
                            textLabel="Data de expiração"
                            place-holder="MM/AAAA"
                            :value="getExpirationMonthAndYear()"
                            @input="updateExpirationMonthAndYear($event)"
                            mask="##/####"
                            invalidInputMessage="Preencha a data de expiração"
                            :validation="expirationDateValidation"
                          />
                        </v-flex>
                        <v-flex xs12 md6 px-3>
                          <InputText
                            ref="cvvInput"
                            type="tel"
                            textLabel="Código de Segurança"
                            place-holder="CVV"
                            autocomplete="cc-csc"
                            v-model="
                              cartLocal.checkoutRequest.creditCardInfo.cvv
                            "
                            mask="####"
                            invalidInputMessage="Preencha o código de segurança"
                            :validation="cvvValidation"
                          />
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-switch
                          v-model="saveCreditCard"
                          inset
                          :label="'Salvar novo cartão?'"
                        ></v-switch>
                      </v-layout>
                    </div>
                    <template v-if="!isPaymentSimplified">
                      <v-flex xs12 md6>
                        <InputText
                          ref="holderCPFInput"
                          type="tel"
                          :textLabel="
                            campaignLandingPageConfig.campaign
                              .permitCnpjDonation
                              ? 'CPF/CNPJ'
                              : 'CPF'
                          "
                          :place-holder="
                            `Digite o ${
                              campaignLandingPageConfig.campaign
                                .permitCnpjDonation
                                ? 'CPF/CNPJ'
                                : 'CPF'
                            }`
                          "
                          v-model="
                            cart.checkoutRequest.creditCardInfo.holder.CPF
                          "
                          :mask="
                            campaignLandingPageConfig.campaign
                              .permitCnpjDonation
                              ? ['###.###.###-##', '##.###.###/####-##']
                              : ['###.###.###-##']
                          "
                          :invalidInputMessage="
                            `Preencha o ${
                              campaignLandingPageConfig.campaign
                                .permitCnpjDonation
                                ? 'CPF/CNPJ'
                                : 'CPF'
                            } do títular`
                          "
                        />
                      </v-flex>
                    </template>
                  </div>
                  <hr class="sm my-2" />
                  <v-layout row wrap>
                    <div
                      class="check-terms"
                      @click="
                        openConsentTerm(PaymentMethod.CreditCard);
                        $gtag(
                          'send',
                          'event',
                          'LiTermoAnuência CartãoCrédito',
                          'Carrinho Doação',
                          'Termo de Anuência'
                        );
                      "
                    >
                      <v-checkbox
                        :value="terms"
                        :disabled="true"
                        label="Declaro que li e concordo com o Termo de Anuência"
                      ></v-checkbox>
                    </div>
                  </v-layout>
                  <hr class="sm my-3" />
                  <button
                    class="btn-boleto mt-2 fw-700"
                    @click="
                      creditCardCheckout();
                      $gtag(
                        'send',
                        'event',
                        'Forma de pagamento Cartao de credito',
                        'Forma de pagamento cartao de credito',
                        'Cartao de credito'
                      );
                    "
                    :disabled="cartItems.length < 1"
                  >
                    <Icon
                      name="fas fa-credit-card"
                      size="16"
                      colorFont="#fff"
                      class="pr-2 text-white"
                    />
                    Concluir {{ donationOrPurchase }} cartão de crédito
                  </button>
                </div>
              </v-tab-item>

              <v-tab-item
                class="px-4 bg-white"
                :value="PaymentMethod.Ticket.toString()"
                v-if="logged"
              >
                <div class="box-info-data-payment"></div>
                <div class="box-total">
                  <v-alert
                    :value="showTicketAlert"
                    type="error"
                    class="alert-cart c-white"
                  >
                    Para emitir o boleto com o seu endereço de cobrança
                    <a :href="buildProfileURL()" style="color: #e00000"
                      >Clique aqui</a
                    >
                    para cadastrá-lo no seu perfil.
                  </v-alert>
                  <div class="mw-total price">
                    <span class="color-theme-texto">Total a ser doado:</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0 notranslate">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                      <span class="color-theme-primaria">{{
                        recurrenceText
                      }}</span>
                    </div>
                  </div>

                  <div
                    v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                    class="mw-total"
                  >
                    <div>
                      <span class="color-theme-texto">Taxa do boleto:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R${{
                          formatterHelper.formatMoney(
                            cartTaxMethodPayment() *
                              (this.cartLocal.creditCardParcelament > 1
                                ? this.cartLocal.creditCardParcelament
                                : 1),
                            2
                          )
                        }}</span
                      >
                    </div>

                    <div>
                      <span class="color-theme-texto">Valor total:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R${{ getParcelamentTotalValue }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="mw-total"
                    v-if="cart.paymentMethodId == paymentMethods.Ticket"
                    @click="resetCreditCardParcelament()"
                  >
                    <InputSelect
                      ref="recurrencePeriodInput"
                      v-model="cart.donationRecurrencePeriodId"
                      :textLabel="
                        'Como deseja fazer esta ' + donationOrPurchase + ':'
                      "
                      helpLabel="Escolha se sua doação será única ou recorrente"
                      :items="campaignDonationRecurrencePeriodList"
                      valueAttribute="id"
                      textAttribute="name"
                      invalidInputMessage="Escolha se sua doação será única ou recorrente"
                    ></InputSelect>
                  </div>
                  <div
                    class="mw-total"
                    v-if="
                      this.cartTotalPrice > 0 &&
                        verifyParcelamentPaymentMethod()
                    "
                  >
                    <span class="color-theme-texto"
                      >Selecione a quantidade de parcelas:</span
                    >
                    <span
                      class="color-theme-texto"
                      style="float: right; font-weight: bold"
                      >Valor mínimo da parcela:
                      {{
                        campaignLandingPageConfig.campaign
                          .minimumParcelamentValue | FormatMoney
                      }}</span
                    >
                    <div>
                      <InputSelect
                        :items="GethowMany"
                        v-model="cart.creditCardParcelament"
                        :disabled="false"
                        valueAttribute="quantidade"
                        textAttribute="name"
                        style="width: 50%"
                        :key="GethowManyTicketParcelament"
                        @input="
                          showParcelamentMessage(cart.creditCardParcelament);
                          keyCreditCardParcelament += 1;
                        "
                      ></InputSelect>
                    </div>
                  </div>
                  <div
                    class="mw-total"
                    v-if="
                      cart.paymentMethodId == paymentMethods.Ticket &&
                        (cart.donationRecurrencePeriodId > 1 ||
                          cart.creditCardParcelament > 1)
                    "
                  >
                    <InputSelect
                      v-if="
                        this.campaignLandingPageConfig.campaign
                          .creditCardParcelament > 2 ||
                          cart.donationRecurrencePeriodId > 1
                      "
                      textLabel="Selecione o dia de vencimento do boleto"
                      textAttribute="name"
                      valueAttribute="id"
                      :items="daysOfMonth"
                      v-model="cart.checkoutRequest.ticketInfo.dayOfMonth"
                      style="width: 30%"
                    />
                  </div>
                </div>
                <div v-if="logged">
                  <v-card-text>
                    <div>
                      <v-layout row wrap>
                        <div
                          class="check-terms"
                          @click="
                            openConsentTerm(PaymentMethod.Ticket);
                            $gtag(
                              'send',
                              'event',
                              'LiTermoAnuência Boleto',
                              'Carrinho Doação',
                              'Termo de Anuência'
                            );
                          "
                        >
                          <v-checkbox
                            :value="terms"
                            :disabled="true"
                            label="Declaro que li e concordo com o Termo de Anuência"
                          ></v-checkbox>
                        </div>
                      </v-layout>
                      <hr class="sm my-3" />
                      <button
                        class="btn-boleto bg-orange fw-600"
                        @click="
                          generateTicket();
                          $gtag(
                            'send',
                            'event',
                            'Forma de pagamento Boleto',
                            'Forma de pagamento boleto',
                            'Boleto'
                          );
                        "
                      >
                        <Icon
                          name="fal fa-barcode-alt"
                          size="16"
                          colorFont="#fff"
                          class="mr-2"
                        />Gerar Boleto Bancário
                      </button>
                    </div>
                    <!-- </GenerateTicket> -->
                  </v-card-text>
                </div>
              </v-tab-item>
              <v-tab-item
                class="px-4 bg-white"
                :value="PaymentMethod.AccountDebit.toString()"
                v-if="logged"
              >
                <div class="box-total">
                  <div class="mw-total price">
                    <span class="color-theme-texto">{{
                      campaignLandingPageConfig.campaign.campaignTypeId ==
                      CampaignTypes.Ticket
                        ? "Total a ser doado:"
                        : "Total a ser doado:"
                    }}</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                      <span class="color-theme-primaria">{{
                        recurrenceText
                      }}</span>
                    </div>
                  </div>
                  <!-- aparece somente se usuario inserir valor menor -->
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="
                      cartTotalPrice > 0 &&
                        cartTotalPrice < minimumPeriodDonationValue
                    "
                  >
                    <span
                      >Mínimo para doação R$
                      {{
                        formatterHelper.formatMoney(
                          minimumPeriodDonationValue,
                          0
                        )
                      }}</span
                    >
                  </div>
                  <div
                    class="text-xs-right f-size-12 mw-total"
                    v-if="selectDollar"
                  >
                    <span
                      >* You will be charged in Brazillian Reais. It will show
                      up as an international payment on your credit card
                      bill.</span
                    >
                  </div>
                  <div class="mw-total">
                    <InputSelect
                      ref="recurrencePeriodInput"
                      v-model="cart.donationRecurrencePeriodId"
                      :textLabel="
                        'Como deseja fazer esta ' + donationOrPurchase + ':'
                      "
                      helpLabel="Escolha se sua doação será única ou recorrente"
                      :items="campaignDonationRecurrencePeriodList"
                      valueAttribute="id"
                      textAttribute="name"
                      invalidInputMessage="Escolha se sua doação será única ou recorrente"
                      v-if="cart.paymentMethodId == paymentMethods.AccountDebit"
                    ></InputSelect>
                  </div>
                  <div
                    v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                    class="mw-total"
                  >
                    <div class="ml-2">
                      <span class="color-theme-texto"
                        >Taxa do débito em conta:</span
                      >
                      <span
                        class="color-theme-texto"
                        style="float: right; font-weight: bold"
                        >R$
                        {{
                          formatterHelper.formatMoney(cartTaxMethodPayment(), 2)
                        }}</span
                      >
                    </div>

                    <div class="ml-2">
                      <span class="color-theme-texto">Valor total:</span>
                      <span
                        class="color-theme-texto"
                        style="float: right; font-weight: bold"
                        >R$
                        {{
                          formatterHelper.formatMoney(
                            cartTotalPrice +
                              cartTaxMethodPayment() *
                                (this.cartLocal.creditCardParcelament
                                  ? this.cartLocal.creditCardParcelament
                                  : 1),
                            2
                          )
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="box-info-data-payment mw-total"
                  v-if="
                    cartLocal.paymentMethodId == paymentMethods.AccountDebit
                  "
                >
                  <h4 class="title-boleto">Dados da Conta</h4>
                  <div class="form-grid">
                    <v-layout row wrap align-start>
                      <v-flex xs12 md6>
                        <InputText
                          ref="holderNameInput"
                          type="text"
                          textLabel="Nome do titular da conta"
                          place-holder="Nome completo do titular"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo.holder
                              .name
                          "
                          invalidInputMessage="Preencha o nome do titular"
                          @input="nameToUpperCase()"
                        />
                      </v-flex>
                      <v-flex xs12 md6>
                        <InputText
                          ref="holderCPFInput"
                          type="tel"
                          textLabel="CPF"
                          place-holder="000.000.000-00"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo.holder
                              .CPF
                          "
                          mask="###.###.###-##"
                          invalidInputMessage="Preencha o CPF do títular"
                        />
                      </v-flex>
                      <v-flex xs8 md8>
                        <InputSelect
                          ref="paymentCompanyCodeInput"
                          textLabel="Banco"
                          textAttribute="name"
                          valueAttribute="code"
                          :items="debitAccountBanks"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo
                              .paymentCompanyCode
                          "
                          invalidInputMessage="Selecione um banco"
                        />
                      </v-flex>
                      <v-flex xs4 md4>
                        <InputSelect
                          ref="paymentCompanyCodeInput"
                          textLabel="Dia para o débito"
                          textAttribute="name"
                          valueAttribute="id"
                          :items="daysOfMonth"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo
                              .dayOfMonth
                          "
                          invalidInputMessage="Selecione o dia para débito"
                        />
                      </v-flex>
                      <v-flex xs12 md5>
                        <InputText
                          ref="bankBranchInput"
                          type="tel"
                          textLabel="Agência"
                          place-holder="0000"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo
                              .bankBranch
                          "
                          invalidInputMessage="Preencha com a agência"
                        />
                      </v-flex>
                      <v-flex xs12 md5>
                        <InputText
                          ref="bankAccountInput"
                          type="tel"
                          textLabel="Conta"
                          place-holder="0000"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo
                              .bankAccount
                          "
                          invalidInputMessage="Preencha com a conta"
                        />
                      </v-flex>
                      <v-flex xs12 md2>
                        <InputText
                          ref="bankAccountDigitInput"
                          type="tel"
                          textLabel="Dígito"
                          place-holder="0000"
                          v-model="
                            cartLocal.checkoutRequest.debitAccountInfo
                              .bankAccountDigit
                          "
                          invalidInputMessage="Dígito da conta"
                        />
                      </v-flex>
                    </v-layout>
                  </div>
                  <hr class="sm my-2" />
                  <v-layout row wrap>
                    <div
                      class="check-terms"
                      @click="openConsentTerm(PaymentMethod.AccountDebit)"
                    >
                      <v-checkbox
                        :value="terms"
                        :disabled="true"
                        label="Declaro que li e concordo com o Termo de Anuência"
                      ></v-checkbox>
                    </div>
                  </v-layout>
                  <hr class="sm my-3" />
                  <button
                    class="btn-boleto mt-2 fw-700"
                    @click="
                      debitAccountCheckout();
                      $gtag(
                        'send',
                        'event',
                        'Forma de pagamento Débito em conta',
                        'Forma de pagamento Débito em conta',
                        'Débito em conta'
                      );
                    "
                    :disabled="cart.cartItems.length < 1"
                  >
                    <Icon
                      name="fas fa-money-check-alt"
                      size="16"
                      colorFont="#fff"
                      class="pr-2 text-white"
                    />
                    Concluir {{ donationOrPurchase }} com débito em conta
                  </button>
                </div>
              </v-tab-item>

              <v-tab-item
                class="px-4 bg-white pix"
                :value="PaymentMethod.Pix.toString()"
                v-if="logged"
              >
                <div class="box-total">
                  <div class="mw-total price">
                    <span class="color-theme-texto">Total a ser doado:</span>
                    <div class="color-theme-texto">
                      R$
                      <h3 class="mr-0 notranslate">
                        {{ formatterHelper.formatMoney(cartTotalPrice, 0) }},⁰⁰
                      </h3>
                    </div>
                  </div>

                  <div
                    v-if="taxOwner == taxOwners.Person && cartTotalPrice > 0"
                    class="mw-total"
                  >
                    <div v-if="cartTaxMethodPayment() > 0">
                      <span class="color-theme-texto">Taxa do pix:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R$
                        {{
                          formatterHelper.formatMoney(
                            cartTaxMethodPayment() *
                              (this.cartLocal.creditCardParcelament > 1
                                ? this.cartLocal.creditCardParcelament
                                : 1),
                            2
                          )
                        }}</span
                      >
                    </div>

                    <div>
                      <span class="color-theme-texto">Valor total:</span>
                      <span
                        class="color-theme-texto notranslate"
                        style="float: right; font-weight: bold"
                        >R$
                        {{
                          formatterHelper.formatMoney(
                            cartTotalPrice +
                              cartTaxMethodPayment() *
                                (this.cartLocal.creditCardParcelament > 1
                                  ? this.cartLocal.creditCardParcelament
                                  : 1),
                            2
                          )
                        }}</span
                      >
                    </div>

                    <div v-if="cartLocal.paymentMethodId == paymentMethods.Pix">
                      <InputSelect
                        ref="recurrencePeriodInput"
                        v-model="cartLocal.donationRecurrencePeriodId"
                        :textLabel="
                          'Como deseja fazer esta ' + donationOrPurchase + ':'
                        "
                        helpLabel="Escolha se sua doação será única ou recorrente"
                        :items="campaignDonationRecurrencePeriodList"
                        valueAttribute="id"
                        textAttribute="name"
                        invalidInputMessage="Escolha se sua doação será única ou recorrente"
                      ></InputSelect>
                    </div>

                    <div
                      v-if="
                        this.cartTotalPrice > 0 &&
                          verifyParcelamentPaymentMethod()
                      "
                    >
                      <span class="color-theme-texto"
                        >Selecione a quantidade de parcelas:</span
                      >
                      <span
                        class="color-theme-texto"
                        style="float: right; font-weight: bold"
                        >Valor mínimo da parcela:
                        {{
                          campaignLandingPageConfig.campaign
                            .minimumParcelamentValue | FormatMoney
                        }}</span
                      >
                      <div>
                        <InputSelect
                          :items="GethowMany"
                          v-model="cartLocal.creditCardParcelament"
                          :disabled="false"
                          valueAttribute="quantidade"
                          textAttribute="name"
                          style="width: 50%"
                          :key="GethowManyTicketParcelament"
                          @input="
                            showParcelamentMessage(
                              cartLocal.creditCardParcelament
                            );
                            keyCreditCardParcelament += 1;
                          "
                        ></InputSelect>
                      </div>
                    </div>

                    <div
                      v-if="
                        (this.campaignLandingPageConfig.campaign
                          .creditCardParcelament > 2 &&
                          cartLocal.creditCardParcelament > 1) ||
                          cartLocal.donationRecurrencePeriodId > 1
                      "
                    >
                      <InputSelect
                        textLabel="Dia da cobrança"
                        textAttribute="name"
                        valueAttribute="id"
                        :items="daysOfMonth"
                        v-model="cartLocal.checkoutRequest.pixInfo.dayOfMonth"
                        style="width: 20%"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="logged">
                  <v-card-text>
                    <div>
                      <v-layout row wrap>
                        <div
                          class="check-terms"
                          @click="
                            openConsentTerm(PaymentMethod.Pix);
                            $gtag(
                              'send',
                              'event',
                              'LiTermoAnuência Pix',
                              'Carrinho Doação',
                              'Termo de Anuência'
                            );
                          "
                        >
                          <v-checkbox
                            :value="terms"
                            :disabled="true"
                            label="Declaro que li e concordo com o Termo de Anuência"
                          ></v-checkbox>
                        </div>
                      </v-layout>
                      <hr class="sm my-3" />
                      <button
                        class="btn-boleto bg-orange fw-600"
                        @click="
                          pixCheckout();
                          $gtag(
                            'send',
                            'event',
                            'Forma de pagamento Pix',
                            'Forma de pagamento Pix',
                            'Pix'
                          );
                        "
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.83025 5.30642C5.64648 5.30642 6.41419 5.61958 6.99139 6.18805L10.1233 9.27459C10.3488 9.49694 10.7171 9.49777 10.9432 9.27433L14.0637 6.1993C14.6409 5.63083 15.4086 5.31767 16.2248 5.31767H16.6007L12.6373 1.41231C11.4029 0.196059 9.40173 0.196059 8.16749 1.41231L4.21548 5.30643L4.83025 5.30642ZM16.2249 16.1824C15.4086 16.1824 14.6409 15.8693 14.0637 15.3008L10.9432 12.226C10.7241 12.0095 10.3423 12.0102 10.1233 12.226L6.99143 15.312C6.41422 15.8805 5.64651 16.1937 4.83029 16.1937H4.21533L8.16753 20.0879C9.40176 21.3041 11.403 21.3041 12.6373 20.0879L16.6007 16.1824H16.2249ZM17.4839 6.1879L19.879 8.54791C21.1133 9.76415 21.1133 11.7359 19.879 12.9522L17.4839 15.3122C17.4312 15.2914 17.3741 15.2784 17.3138 15.2784H16.2248C15.6618 15.2784 15.1107 15.0534 14.7129 14.6611L11.5925 11.5866C11.027 11.0289 10.0402 11.029 9.47408 11.5864L6.34222 14.6724C5.94439 15.0646 5.39326 15.2896 4.83027 15.2896H3.49125C3.43413 15.2896 3.38076 15.3028 3.33028 15.3215L0.925749 12.9522C-0.308583 11.736 -0.308583 9.76416 0.925749 8.54791L3.33038 6.1785C3.38076 6.1972 3.43413 6.21047 3.49125 6.21047H4.83027C5.39326 6.21047 5.94439 6.43539 6.34222 6.82775L9.47438 9.91402C9.7663 10.2015 10.1497 10.3454 10.5334 10.3454C10.9168 10.3454 11.3005 10.2015 11.5925 9.91374L14.7129 6.83899C15.1107 6.44671 15.6618 6.2217 16.2248 6.2217H17.3138C17.3741 6.2217 17.4312 6.20871 17.4839 6.1879Z"
                            fill="currentColor"
                          />
                        </svg>
                        Gerar dados para o pix
                      </button>
                    </div>
                    <!-- </GenerateTicket> -->
                  </v-card-text>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div
      v-if="logged"
      class="f-600-column bg-white pa-4 display-flex justify-space-between"
    >
      <div class="box-quality-terms m-600-bottom-24 mb-5 color-theme-texto">
        Ao fazer uma {{ donationOrPurchase }}, você concorda com nossos
        <div>
          <a
            href="javascript:;"
            class="color-theme-texto"
            @click="
              showServiceTerms = !showServiceTerms;
              $gtag(
                'send',
                'event',
                'Termos de Uso',
                'Carrinho Doação',
                'Termos de uso'
              );
            "
            >Termos de uso</a
          >
          e
          <a
            class="color-theme-texto"
            href="javascript:;"
            @click="
              showPolicyTerms = !showPolicyTerms;
              $gtag(
                'send',
                'event',
                'Política Privacidade',
                'Carrinho Doação',
                'Política de privacidade'
              );
            "
            >Política de Privacidade.</a
          >
        </div>
      </div>
    </div>
    <SimulationValueFloat
      v-if="simulationValue > 0"
      :dialog="true"
      :valueToDiscount="0"
      :suggestedValue="simulationValue"
      @openSimulation="toggleSimulation"
    ></SimulationValueFloat>

    <ModalPix ref="modalPix" @payed="finishPix" />

    <AucAlert
      ref="removeCartItemAlert"
      :title="
        'Você tem certeza que deseja excluir esse ' +
          itemOrProject +
          ' do seu carrinho?'
      "
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removeCartItem(selectedCartItem)"
    />
    <AucAlert
      ref="confirmWithValueSimulation"
      icon="fal fa-calculator-alt"
      :title="textSimulationConfirm"
      confirmButtonText="Continuar"
      cancelButtonText="Alterar valor"
      @confirm="callPaymentMethod"
      :withStorage="false"
    /><AucAlert
      ref="parcelamentMessage"
      :title="getParcelamentMessageText"
      confirmButtonText="Ok"
    />
  </div>
</template>

<script type="plain/text">
import { mapGetters, mapActions } from "vuex";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import { TaxOwners } from "@/scripts/models/enums/taxOwners.enum";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";
import DonationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import CompanyService from "@/scripts/services/company.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import SimulationValueFloat from "@/components/SimulationValueFloat.vue";

export default {
    components: {
        FileImage,
        SimulationValueFloat
    },
        props: {
        landingPageOptions: null,
    },
    data() {
    return {
        companyService: new CompanyService(),
        formatterHelper: new FormatterHelper(),
        DonationRecurrencePeriod: DonationRecurrencePeriod,
        PaymentMethod: PaymentMethod,
        CampaignTypes: CampaignTypes,
        ProjectTypes: ProjectTypes,
        PaymentMethodProvider: PaymentMethodProvider,
        taxOwners: TaxOwners,
        parcelamentsInputId: 0,
        hasCompany: false,
        titleCompany: "Votorantim",
        terms: false,
        keyCreditCardParcelament: 0,
        taxOwner: TaxOwners.Company,
        creditCards: [],
        saveCreditCard: false,
        daysOfMonth: [],
        GethowManyTicketParcelament: 1,
        showServiceTerms: false,
        showPolicyTerms: false,
        simulationValue: 0,
    };
    },
    async created() {
        await this.$store.dispatch("setLandingPageConfig");
        this.setIncomeTax();
    },
    beforeMount() {
    this.$store.dispatch("loadCampaignCheckoutData");
    this.$store.dispatch("checkCampaignType");
    this.$store.dispatch("getCreditCards");
    this.daysOfMonth = [
        { id: 1, name: 1 },
        { id: 10, name: 10 },
        { id: 20, name: 20 },
    ];
    },

    mounted() {
        this.setSuggestedValueFromSessionStorage();
    },
    computed: {
    ...mapGetters([
        "campaignCheckoutData",
        "cartLocal",
        "cartItems",
        "usdDonationInfo",
        "isIncetived",
        "creditCards",
        "selectedCardId",
        "addNewCard",
        "campaignLandingPageConfig",
        "incomeTax"
    ]),
    enabledUSDDonation: {
        get() {
            return this.$store.getters.usdDonationInfo.enabled;
        },
        set(value) {
            this.$store.dispatch("toggleUSDDonation", value);
        },
    },
    isPaymentSimplified() {
        return (
            this.cartLocal.donationRecurrencePeriodId ==
            DonationRecurrencePeriod.Single &&
            this.providerId == PaymentMethodProvider.PagarMe
        );
        },
    recurrenceText() {
        let recurrencePeriod = parseInt(this.cartLocal.donationRecurrencePeriodId);

        if (recurrencePeriod === DonationRecurrencePeriod.EveryOneMonth) {
            return "por mês";
        }
        if (recurrencePeriod === DonationRecurrencePeriod.EveryTwoMonths) {
            return "a cada 2 meses";
        }
        if (recurrencePeriod === DonationRecurrencePeriod.EveryThreeMonths) {
            return "a cada 3 meses";
        }
        if (recurrencePeriod === DonationRecurrencePeriod.EveryFourMonths) {
            return "a cada 4 meses";
        }
        if (recurrencePeriod === DonationRecurrencePeriod.EverySixMonths) {
            return "a cada 6 meses";
        }
        if (recurrencePeriod === DonationRecurrencePeriod.EveryTwelveMonths) {
            return "por ano";
        }

        return "";
    },
    campaignDonationRecurrencePeriodList() {
        if (this.campaignLandingPageConfig.campaign == null) return [];
        var list = this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList.map(
            function (campaignDonationRecurrencePeriod) {
                return {
                    id: campaignDonationRecurrencePeriod.donationRecurrencePeriodId,
                    name: campaignDonationRecurrencePeriod.donationRecurrencePeriod.name,
                };
            }
        );

        if (list.length == 1) this.cartLocal.donationRecurrencePeriodId = list[0].id;
        return list;
    },
    parcelamentsDivided() {
        if (this.parcelaments == null) return [];
        let options = JSON.parse(JSON.stringify(this.parcelaments));
        options.forEach((el) => {
            let value =
                this.cartTotalPrice != 0 && el.howMany != 0
                    ? this.cartTotalPrice / el.howMany
                    : this.cartTotalPrice;
            if (el.howMany != 0)
                el.description =
                    el.howMany +
                    "x R$ " +
                    this.formatterHelper.formatMoney(value, 2) +
                    " " +
                    el.description;
            else
                el.description =
                    el.description +
                    " 1x R$ " +
                    this.formatterHelper.formatMoney(this.cartTotalPrice, 2);
        });
        if (options.length == 1) this.cartLocal.parcelamentId = options[0].id;
        return options;
    },
    donationOrPurchase() {
        return "doação";
    },
    getParcelamentTotalValue(){
        var parcelament = 1;
        if (this.cartLocal.creditCardParcelament && this.cartLocal.creditCardParcelament > 1 && this.cartLocal.paymentMethodId != PaymentMethod.CreditCard) {
            parcelament = this.cartLocal.creditCardParcelament;
        }
        var parcelamentPrice = parseFloat((this.cartTotalPrice / parcelament).toFixed(2))
        var taxTotalPrice = this.cartTaxMethodPayment() * parcelament;
        var totalParcelamentValue = Math.ceil(parcelamentPrice * parcelament) + taxTotalPrice;
        return this.formatterHelper.formatMoney(totalParcelamentValue) ;
    },
    minimumPeriodDonationValue() {
        let minimumDonationValue = 0;
        if (
            this.cartLocal.paymentMethodId == this.paymentMethods.CreditCard &&
            this.cartLocal.donationRecurrencePeriodId
        ) {
            let recurrencePeriod = parseInt(this.cartLocal.donationRecurrencePeriodId);
            let campaignDonationRecurrencePeriodList = this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList.find(
                (cdrp) => cdrp.donationRecurrencePeriodId == recurrencePeriod
            );

        minimumDonationValue =
            campaignDonationRecurrencePeriodList.minimumDonationValue > 0
                ? campaignDonationRecurrencePeriodList.minimumDonationValue
                : this.campaignLandingPageConfig.campaign.minimumDonationValue;

        if (!this.cartLocal.campaign){
        this.cartLocal.campaign = this.campaignLandingPageConfig.campaign;
        }
        this.cartLocal.campaign.minimumDonationValue = minimumDonationValue;
    } else {
        minimumDonationValue = this.campaignLandingPageConfig.campaign.minimumDonationValue;

        if (!this.cartLocal.campaign){
            this.cartLocal.campaign = this.campaignLandingPageConfig.campaign;
        }

        this.cartLocal.campaign.minimumDonationValue = this.campaignLandingPageConfig.campaign.minimumDonationValue;
    }
        return minimumDonationValue;
    },
    showTicketAlert() {
        return false;
    },
},
    watch: {
        logged: {
            immediate: true,
            handler(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.companyService
                    .getBySignedInUser()
                    .then(this.onCompanyLoaded)
                    .then(this.listParcelamentsPayRoll)
                    .then(this.reloadPaymentMethods);
                this.locationService.listAllState(this.listAllStateCallback);
            }
            },
        },
        campaignLandingPageConfig: {
            handler(newValue,oldValue) {
                console.log(newValue)
                console.log(oldValue)
            }
        }
    },
    methods: {
    ...mapActions([
        "removeToCart",
        "setIncomeTax"
    ]),
    addMoreProjects() {
        this.$router.push({
            name: Routes.app.CampaignLandingPage,
            query: { open: "projetos" },
        });
    },
    openSimulationAlertCart() {
        this.$refs.confirmationComponent.open();
    },
    toggleSimulation() {
        this.landingPageOptions.simulationDrawer = !this.landingPageOptions
            .simulationDrawer;
    },
    getSimulationResult() {
        var result = sessionStorage.result ? sessionStorage.result : 0;
        return result;
    },
    containsPaymentMethodAndFitsTheChosenRecurrencePeriod(paymentMethod) {
        console.log('campaignLandingPage', this.$store.getters)
        console.log('campaignLandingPageZ', this.$store.getters.campaignLandingPageConfig)
        console.log(this.campaignLandingPageConfig.id)
        if ((this.cartLocal && this.cartLocal.campaign && this.cartLocal.campaign.campaignPaymentMethodList) ||
            (this.campaignLandingPageConfig && this.campaignLandingPageConfig.campaign && this.campaignLandingPageConfig.campaign.paymentMethodList)) {

            let paymentMethodList = this.campaignLandingPageConfig.campaign.paymentMethodList || this.cartLocal.campaign.campaignPaymentMethodList;

            for (let i = 0; i < paymentMethodList.length; i++) {
                if (paymentMethodList[i] == paymentMethod)
                    return true;
            }
            return false;
        }
        else {
            return true;
        }
    },
    checkPayRollInput(){
        this.showPayRollInput = this.parcelaments != null;

        if (this.campaignLandingPageConfig.campaign.campaignCompanyPaymentMethodList) {
            let campaignCompanyPaymentMethod = this.campaignLandingPageConfig.campaign.campaignCompanyPaymentMethodList.filter(paymentMethod => {
                    return paymentMethod.paymentMethodId == this.paymentMethods.PayrollDeduction
                });

            if (campaignCompanyPaymentMethod[0].donationRecurrencePeriodId && campaignCompanyPaymentMethod[0].donationRecurrencePeriodId > 0) {
                if (campaignCompanyPaymentMethod[0].donationRecurrencePeriodId > 1) {
                    this.showPayRollInput = false;
                }
            }
        }

        if(this.cartLocal.donationRecurrencePeriodId > 1)
            this.showPayRollInput = false;

        if (!this.showPayRollInput) {
            this.cartLocal.parcelamentId = null;
        }
        this.parcelamentsInputId++;
    },
    onCompanyLoaded(company) {
        if (company) {
            this.hasCompany = true;
            this.companyId = company.id;
            this.titleCompany = company.entity.name;
            this.cnpjCompany = company.entity.cnpj;
            this.textCompany = "";
        } else {
            if (this.containsPaymentMethodAndFitsTheChosenRecurrencePeriod(PaymentMethod.PayrollDeduction)
                && (this.cartLocal != null && this.cartLocal.campaign && this.cartLocal.campaign.isPublic && this.cartLocal.campaign.company)) {

                this.hasCompany = true;
                this.companyId = this.cartLocal.campaign.company.id;
                this.titleCompany = this.cartLocal.campaign.company.entity.name;
                this.cnpjCompany = this.cartLocal.campaign.company.entity.cnpj;
                this.textCompany = "";
            }
        }
    },
    acceptConsentTerm() {
        this.terms = true;
    },
    },
    keyCreditCardParcelamentChange(event){
        this.resetCreditCardParcelament();
        this.keyCreditCardParcelament = event;
    },
    showParcelamentMessage(parcelament){
        var month = new Date().getMonth() + 1;
        var totalMonth = month + parseInt(parcelament);
        if (totalMonth > 12 && this.campaignLandingPageConfig.campaign.campaignTypeId == CampaignTypes.Incentivada) {
            this.$refs.parcelamentMessage.open();
        }
    },
    gaLightBoxDadosTitular() {
        if (ga)
        return ga("send", "pageview", "/carrinho/cartao-credito/dados-titular");
    },
    selectCreditCard(cardId, card) {
        if (this.selectedCardId == cardId) this.selectedCardId = 0;
        else this.selectedCardId = cardId;

        if (card) {
            this.cartLocal.checkoutRequest.creditCardInfo.cardNumber = card.lastDigits;
        }
    },
    deleteCreditCard(creditCardId) {
        this.cartService.deleteCreditCard(creditCardId).then(() => {
            this.getCreditCards();
            this.CommonHelper.swal("Cartão excluido", null, "success");
        });
    },
    nameToUpperCase() {
    if (
        this.cartLocal &&
        this.cartLocal.checkoutRequest &&
        this.cartLocal.checkoutRequest.creditCardInfo &&
        this.cartLocal.checkoutRequest.creditCardInfo.holder &&
        this.cartLocal.checkoutRequest.creditCardInfo.holder.name
    )
        this.cartLocal.checkoutRequest.creditCardInfo.holder.name = this.cartLocal.checkoutRequest.creditCardInfo.holder.name.toUpperCase();
    },
    getExpirationMonthAndYear() {
        if (
            this.cartLocal &&
            this.cartLocal.checkoutRequest &&
            this.cartLocal.checkoutRequest.creditCardInfo &&
            this.cartLocal.checkoutRequest.creditCardInfo.expirationMonth &&
            this.cartLocal.checkoutRequest.creditCardInfo.expirationYear
        ) {
            return (
                this.cartLocal.checkoutRequest.creditCardInfo.expirationMonth.toString() +
                this.cartLocal.checkoutRequest.creditCardInfo.expirationYear.toString()
            );
        }
    return "";
    },
    updateExpirationMonthAndYear(event) {
        if (event && event.length == 6) {
            this.cartLocal.checkoutRequest.creditCardInfo.expirationMonth = event.substring(
                0,
                2
            );
            this.cartLocal.checkoutRequest.creditCardInfo.expirationYear = event.substring(
                2,
                6
            );
        } else {
            this.cartLocal.checkoutRequest.creditCardInfo.expirationMonth = "";
            this.cartLocal.checkoutRequest.creditCardInfo.expirationYear = "";
        }
    },
    expirationDateValidation() {
        if (
            !this.cartLocal ||
            !this.cartLocal.checkoutRequest ||
            !this.cartLocal.checkoutRequest.creditCardInfo ||
            !this.cartLocal.checkoutRequest.creditCardInfo.expirationMonth ||
            !this.cartLocal.checkoutRequest.creditCardInfo.expirationYear
        )
        return false;
    let currentYear = this.$moment().year();
    let currentMonth = this.$moment().month();
    let expirationMonth = this.cartLocal.checkoutRequest.creditCardInfo
        .expirationMonth;
    let expirationYear = this.cartLocal.checkoutRequest.creditCardInfo
        .expirationYear;
    if (expirationMonth > 12) return false;
    if (
        expirationYear > currentYear ||
        (expirationYear == currentYear && expirationMonth >= currentMonth)
    ) {
        return true;
    }
    return false;
    },
    cartTaxMethodPayment() {
        let totalPrice = this.cartTotalPrice;
        let tax = 0;

        if (totalPrice == 0) {
            return 0;
        }

        if (this.taxOwner == this.taxOwners.Company) {
            this.cartLocal.taxValue = 0;
            return 0;
        }

        if (this.cartLocal.paymentMethodId == PaymentMethod.Ticket) {
            tax = this.configManager.taxBoleto;
        } else if (
            this.cartLocal.paymentMethodId == PaymentMethod.CreditCard &&
            (this.cartLocal.donationRecurrencePeriodId == null ||
                this.cartLocal.donationRecurrencePeriodId ==
                DonationRecurrencePeriod.Single)
        ) {
            var parcelament = (!this.cartLocal.creditCardParcelament || this.cartLocal.creditCardParcelament == 0) ? 1 : this.cartLocal.creditCardParcelament;
            tax =
                totalPrice /
                ((100 - this.receiverParcelament.find(x => x.parcelament == parcelament).singleTransactionValue) / 100) -
                totalPrice;

        } else if (
            this.cartLocal.paymentMethodId == PaymentMethod.CreditCard &&
            this.cartLocal.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
        ) {
            tax =
                totalPrice /
                ((100 - this.receiverParcelament.find(x => x.parcelament == 1).singleTransactionValue) / 100) -
                totalPrice;
        } else if (
            this.cartLocal.paymentMethodId == PaymentMethod.AccountDebit &&
            (this.cartLocal.donationRecurrencePeriodId == null ||
                this.cartLocal.donationRecurrencePeriodId ==
                DonationRecurrencePeriod.Single)
        ) {
            tax =
                totalPrice /
                ((100 - this.configManager.taxAccountDebit) / 100) -
                totalPrice;
        } else if (
            this.cartLocal.paymentMethodId == PaymentMethod.AccountDebit &&
            this.cartLocal.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
        ) {
            tax =
                totalPrice /
                ((100 - this.configManager.taxRecurrentAccountDebit) / 100) -
                totalPrice;
        }
        else if (this.cartLocal.paymentMethodId == PaymentMethod.Pix) {
            tax =
                totalPrice /
                ((100 - this.configManager.taxPix) / 100) -
                totalPrice;
            tax = tax <= 5.51 ? tax : 5.51;
            if (this.cartLocal.creditCardParcelament > 1) {
                tax = this.getPixTaxParcelamentValue(this.cartLocal.creditCardParcelament)
                this.cartLocal.taxValue = tax * this.cartLocal.creditCardParcelament
                return tax;
            }
        }
        this.cartLocal.taxValue = tax;
        return tax;
    },
    debitAccountCheckout() {
        if (this.needAnAdrress()) return;
        if (!this.isValid()) return;
        if (
            this.simulationValue - this.cartTotalPrice > 0 &&
            !this.confirmAlreadyOpen
        ) {
            this.openConfirmationDonation("debitAccountCheckout");
            return;
        }
        if (!this.isDebitAccountInformationValid()) return;
        if (!this.terms) {
            this.CommonHelper.swal(
                "Você precisa aceitar os termos de Anuência para concluir a compra!"
            );
            return;
        }
        this.cartLocal.checkoutRequest.debitAccountInfo.billingAddress.country = "br";
        this.recaptcha().then((token) => {
            this.cartLocal.checkoutRequest.tokenRecaptcha = token;
            this.cartService
            .debitAccountCheckout(this.cart)
            .then(this.onCheckout, (data) => {
                if (data && data.errorMessage) {
                    this.rollbackRemoveItem(payload.item, data.errorMessage);
                }
            });
        })
    },
    setSuggestedValueFromSessionStorage() {
        this.simulationValue = this.getSuggestedValueFromSessionStorage();
    },
    removeCartItem(cartItem) {
        this.removeToCart(cartItem);
    },
    getParcelamentMessageText(){
        var currentYear = new Date().getFullYear();
        return `Somente os valores pagos até o dia 26/12/${currentYear} poderão ser deduzidos em seu Imposto de Renda PF ${currentYear + 1} (exercício ${currentYear}).
        Caso queira que todo o valor doado seja deduzido no IR do próximo ano, por favor, efetue todos os pagamentos até essa data.
        As informações referentes aos valores que devem ser considerados para abatimento do IRPF ${currentYear + 1} serão enviadas por e-mail, no Informe de Doações.
        `;
    },
    finishPix() {
        this.$store.dispatch("finishPix");
    },
    redirectToCongratsPage() {
        this.dialogSuccess = true;
        this.setCartSuccess({
            cartId: this.finishedCartId,
            totalValue: this.finishedCartTotalValue,
            campaign: this.campaignLandingPageConfig.campaign,
            paymentMethodId: this.finishedPaymentMethodId,
        });

        if (this.redirectToTicket) {
            let routeData = this.$router.resolve({
                name: Routes.web.GenerateTicket,
            });
            window.open(routeData.href, "_blank");
        }
        this.clearCart();
        this.$router.push({
            name: Routes.app.Congrats,
            query: { id: this.finishedCartId },
        });
    },
};
</script> -->
